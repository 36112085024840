import React, { useEffect, useState } from "react";
import {
  BackLink,
  Button,
  SummaryList,
  WarningCallout,
} from "nhsuk-react-components";
import { IsoToDateFornat, toTitleCase } from "../../_shared/shared.functions";
import { NhsdOrganisation } from "../../_shared/shared.models";
import { RegionalUserContext } from "../user/pages/RegionalUserRoot";
import { useNavigate, useOutletContext } from "react-router-dom";
import useDocumentTitle from "../../_shared/hooks/useDocumentTitle";
import useAnalytics from "../analytics/hooks/useAnalytics";

const createLineBreaks = (text: string) => {
  const parts = text.split("<br>");
  return parts.map((part, index) => (
    <React.Fragment key={index}>
      {part}
      {index < parts.length - 1 && <br />}
    </React.Fragment>
  ));
};

interface SummaryFields {
  key: string;
  value: string;
}

const mapSummaryFields = (field: NhsdOrganisation | null): SummaryFields[] => {
  if (!field) {
    return [];
  }

  return [
    {
      key: "Name",
      value: toTitleCase(field.Name) || "",
    },
    {
      key: "Type",
      value: toTitleCase(field.PrimaryRoleDescription) || "",
    },
    {
      key: "ODS code",
      value: field.OrgId || "",
    },
    {
      key: "Address",
      value: `${toTitleCase(field.AddressLine1) || ""} <br> ${toTitleCase(field.Town) || ""} <br> ${field.PostCode || ""}`,
    },
  ];
};

const RegionalOrganisationSummary: React.FC = () => {
  const { organisation } = useOutletContext<RegionalUserContext>();
  const navigate = useNavigate();
  useDocumentTitle("Organisation Summary");
  useAnalytics(["regional", "Organisation Summary"]);
  const [fields, setFields] = useState<SummaryFields[]>([]);

  useEffect(() => {
    setFields(mapSummaryFields(organisation));
  }, [organisation]);

  const handleClick = () => {
    navigate("add-lead-user");
  };

  return (
    <div className="nhsuk-grid-row">
      <div className="nhsuk-grid-column-two-thirds">
        <BackLink
          asElement="button"
          onClick={() => {
            navigate("/regional/add-organisation");
          }}
        >
          Back
        </BackLink>
        <legend className="nhsuk-fieldset__legend nhsuk-fieldset__legend--xl nhsuk-u-margin-bottom-7">
          <h1 className="nhsuk-fieldset__heading">Organisation</h1>
        </legend>
        {organisation.LegalEndDate && (
          <div className="nhsuk-warning-callout">
            <WarningCallout.Label>Important</WarningCallout.Label>
            <p>
              This organisation was legally closed on{" "}
              {IsoToDateFornat(organisation.LegalEndDate)} and cannot be added.
            </p>
          </div>
        )}
        <SummaryList>
          {fields.map(({ key, value }) => (
            <SummaryList.Row key={key}>
              <SummaryList.Key>{key}</SummaryList.Key>
              <SummaryList.Value>{createLineBreaks(value)}</SummaryList.Value>
            </SummaryList.Row>
          ))}
        </SummaryList>
        {!organisation.LegalEndDate && (
          <Button
            onClick={handleClick}
            className="nhsuk-button nhsuk-u-margin-top-5"
            data-module="nhsuk-button"
          >
            Continue
          </Button>
        )}
      </div>
    </div>
  );
};

export default RegionalOrganisationSummary;
