import { useState } from "react";
import React from "react";
import { Button, Col, NavLink, Row, Spinner } from "reactstrap";
import { Link, Location, useLocation, useNavigate } from "react-router-dom";
import { useFormik } from "formik";
import { object, string } from "yup";
import patientService from "./patient.service";
import {
  IsoToUkDate,
  HandleValueLength,
  ScrollToBottom,
  scrollToElementId,
  IsoDateToHhMmTime,
} from "../../_shared/shared.functions";
import { ValidNhsNumber } from "../../_shared/shared.validation";
import useDocumentTitle from "../../_shared/hooks/useDocumentTitle";
import {
  PatientSearchAnalyticsPrimaryCategory,
  PatientPageTitles,
  PatientSearchNhsNumberAnayticsPageName,
} from "./patient.enums";
import useAnalytics from "../analytics/hooks/useAnalytics";
import { ErrorSummary, TextInput } from "nhsuk-react-components";
import { PatientList } from "./PatientList";
import {
  Patient,
  PatientSearchResponse,
  PatientSearchType,
} from "./patient.models";

export interface PatientSearchNhsNumberNavigationState {
  newPatient?: Patient;
  newVaccination?: {
    PatientId: number;
    RedirectToVaccination: boolean;
    Name: string;
    Id: string;
    AuditDateTime: string;
  };
}

export default function PatientSearchNhsNumber() {
  useAnalytics([
    "service",
    PatientSearchAnalyticsPrimaryCategory.PrimaryCategory,
    PatientSearchNhsNumberAnayticsPageName.SubCategory1,
  ]);
  useDocumentTitle(PatientPageTitles.NhsNumberPatientSearch);

  const location: Location<PatientSearchNhsNumberNavigationState> =
    useLocation();
  const navigate = useNavigate();
  const [vaccinationRedirect, setVaccinationRedirect] = useState(
    location.state?.newVaccination || null,
  );
  const [loading, setLoading] = useState(false);
  const [patient, setPatient] = useState<Patient | null>(null);
  const [searchValues, setSearchValues] = useState("");

  const patientSearchType: PatientSearchType = {
    NhsNumberSearch: true,
    PdsSearch: false,
    RavsSearch: false,
  };

  const [newPatient, setNewPatient] = useState(
    location?.state?.newPatient || null,
  );
  const [hoveredField, setHoveredField] = useState(null);

  const closeNotification = () => setNewPatient(null);
  const resetVaccinationRedirect = () => {
    setVaccinationRedirect(null);
    navigate(`/patient/search/nhs-number`);
  };

  const viewRecord = (patient: Patient) => {
    // navigate to the patient record
    navigate(`/patient/${patient.PatientId}`);
  };

  const formik = useFormik({
    initialValues: { NhsNumber: "" },
    validationSchema: object({
      NhsNumber: string()
        .min(10, "Enter 10 digits")
        .max(10, "Enter 10 digits")
        .test(
          "isValidNhsNumber",
          "Enter a correct NHS number",
          (value) => !value || ValidNhsNumber(value),
        )
        .required("Enter an NHS number"),
    }),
    enableReinitialize: true,
    validateOnChange: false,
    onSubmit: async (values) => {
      setLoading(true);
      setSearchValues(values.NhsNumber);
      try {
        const response = await patientService.nhsNumberSearch$(
          values.NhsNumber,
        );
        await processResponse(response);
        ScrollToBottom();
      } finally {
        setLoading(false);
      }
    },
  });

  const processResponse = async (response: PatientSearchResponse | null) => {
    if (!response || !response.PdsPatient) {
      setPatient(null);
      return;
    }

    const pdsPatient = response.PdsPatient;
    const ravsPatientId = response?.RavsPatient?.PatientId;

    if (pdsPatient.TooManyReturnedResults) {
      setPatient(pdsPatient);
    } else if (ravsPatientId) {
      setPatient({ ...pdsPatient, PatientId: ravsPatientId });
    } else {
      setPatient(pdsPatient);
    }
  };

  return (
    <>
      <fieldset className="nhsuk-fieldset col">
        <legend className="nhsuk-fieldset__legend nhsuk-fieldset__legend--xl">
          {newPatient && (
            <NotificationCard
              patient={newPatient}
              closeNotification={closeNotification}
              viewRecord={() => viewRecord(newPatient)}
            />
          )}

          {formik.submitCount > 0 && Object.keys(formik.errors).length > 0 && (
            <ErrorSummary id="formErrors">
              <ErrorSummary.Title>There is a problem</ErrorSummary.Title>
              <ErrorSummary.Body>
                <ErrorSummary.List>
                  {Object.keys(formik.errors).map((fieldName, index) => (
                    <ErrorSummary.Item key={index}>
                      <button
                        className={`anchor-style ${hoveredField === fieldName ? "hovered-color" : "error-color"}`}
                        onMouseEnter={() => setHoveredField(fieldName)}
                        onMouseLeave={() => setHoveredField(null)}
                        key={index}
                        onClick={() => scrollToElementId(fieldName)}
                      >
                        {formik.errors[fieldName]}
                      </button>
                    </ErrorSummary.Item>
                  ))}
                </ErrorSummary.List>
              </ErrorSummary.Body>
            </ErrorSummary>
          )}

          {vaccinationRedirect && (
            <VaccinationRedirectCard
              vaccinationRedirect={vaccinationRedirect}
              resetRedirect={resetVaccinationRedirect}
            />
          )}

          <h1 className="nhsuk-fieldset__heading">Find a patient</h1>
        </legend>

        <div className="nhsuk-tabs" data-module="nhsuk-tabs">
          <TabNav />
          <div className="nhsuk-tabs__panel pt-0" id="by-nhs-number">
            <br />
            <PatientSearchForm formik={formik} loading={loading} />
          </div>
        </div>
        {!loading && (
          <PatientList
            patient={patient}
            patientSearchType={patientSearchType}
            NhsNoSearchValues={searchValues}
            patientSearchValues={null}
          ></PatientList>
        )}
      </fieldset>
    </>
  );
}

interface NotificationCardProps {
  patient: Patient;
  closeNotification: () => void;
  viewRecord: () => void;
}

function NotificationCard({
  patient,
  closeNotification,
  viewRecord,
}: NotificationCardProps) {
  return (
    <div className="col-12">
      <div className="card mb-4" style={{ border: "2px solid green" }}>
        <div
          className="card-header text-white background-success"
          style={{ fontSize: "24px", position: "relative" }}
        >
          Patient added
          <span
            style={{
              position: "absolute",
              top: 10,
              right: 10,
              fontSize: "16px",
            }}
          >
            <u
              className="text-white cursor-pointer"
              onClick={closeNotification}
            >
              Close
            </u>
          </span>
        </div>
        <div className="card-body">
          <p className="card-text mb-0">
            {patient.FirstName} {patient.LastName} with date of birth{" "}
            {IsoToUkDate(patient.DateOfBirth)} has been added to RAVS.
          </p>
          <div className="notification-buttons mt-2">
            <u
              className="text-primary cursor-pointer"
              style={{ fontSize: "16px" }}
              onClick={viewRecord}
            >
              View Record
            </u>
          </div>
        </div>
      </div>
    </div>
  );
}

interface VaccinationRedirectCardProps {
  vaccinationRedirect: {
    PatientId: number;
    Name: string;
    AuditDateTime: string;
  };
  resetRedirect: () => void;
}

function VaccinationRedirectCard({
  vaccinationRedirect,
  resetRedirect,
}: VaccinationRedirectCardProps) {
  const navigate = useNavigate();

  return (
    <div className="card mb-4" style={{ border: "2px solid green" }}>
      <div
        className="card-header text-white background-success"
        style={{ fontSize: "24px", position: "relative", borderRadius: 0 }}
      >
        Record saved
        <span
          style={{
            position: "absolute",
            top: 10,
            right: 10,
            fontSize: "16px",
            cursor: "pointer",
          }}
        >
          <u className="text-white cursor-pointer" onClick={resetRedirect}>
            Close
          </u>
        </span>
      </div>
      <div className="card-body">
        <p className="card-text mb-0">
          You successfully saved {vaccinationRedirect.Name} record at{" "}
          {IsoDateToHhMmTime(vaccinationRedirect.AuditDateTime)}
        </p>
        <u
          className="text-primary cursor-pointer mt-0"
          style={{ fontSize: "16px", cursor: "pointer" }}
          onClick={() => navigate(`/patient/${vaccinationRedirect.PatientId}`)}
        >
          View record
        </u>
      </div>
    </div>
  );
}

function TabNav() {
  return (
    <ul
      className="nhsuk-tabs__list"
      aria-label="Patient search by NHS number tab"
    >
      <li className="nhsuk-tabs__list-item nhsuk-tabs__list-item--selected">
        <NavLink href="#" active>
          By NHS number
        </NavLink>
      </li>
      <li className="nhsuk-tabs__list-item">
        <NavLink tag={Link} to="/patient/search/pds">
          By demographics
        </NavLink>
      </li>
      <li className="nhsuk-tabs__list-item">
        <NavLink tag={Link} to="/patient/search/records">
          By local records
        </NavLink>
      </li>
    </ul>
  );
}

function PatientSearchForm({ formik, loading }) {
  return (
    <form onSubmit={formik.handleSubmit}>
      <div
        className={
          formik.submitCount > 0 && formik.errors.NhsNumber
            ? "nhsuk-form-group nhsuk-form-group--error"
            : "nhsuk-form-group"
        }
      >
        <h2 className="nhsuk-table__caption">Search by NHS number</h2>
        <h3 className="nhsuk-label">The quickest way to find a patient.</h3>
        <div className="nhsuk-hint" id="select-vaccines-hint">
          Enter a 10 digit NHS number
        </div>
        {formik.submitCount > 0 && formik.errors.NhsNumber && (
          <span className="nhsuk-error-message" id="VaccineProgramIdError">
            <span className="nhsuk-u-visually-hidden">Error:</span>{" "}
            {formik.errors.NhsNumber}
          </span>
        )}
        <TextInput
          width={20}
          value={formik.values.NhsNumber}
          id="NhsNumber"
          name="NhsNumber"
          label="NHS number"
          inputMode="numeric"
          pattern="[0-9]*"
          onChange={(e) => {
            const rawValue = (e.target as HTMLInputElement).value;
            const sanitizedValue = rawValue
              .replace(/\s+/g, "")
              .replace(/\D/g, "");
            const trimmedValue = sanitizedValue.slice(0, 10);
            formik.setFieldValue("NhsNumber", trimmedValue);
            HandleValueLength({ target: { value: trimmedValue } }, formik, 10);
          }}
        />
      </div>
      <Row>
        <Col className="pt-2">
          {loading ? (
            <Spinner />
          ) : (
            <Button
              type="submit"
              className="nhsuk-button"
              data-module="nhsuk-button"
              disabled={loading}
            >
              Search
            </Button>
          )}
        </Col>
      </Row>
    </form>
  );
}
